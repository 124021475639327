<template />

<script>
export default {
  name: 'Index',
}
</script>

<style scoped>

</style>
